@import '../../styles/colours';

.sidebar-container {
  display: grid;
  background: $grey;
  grid-template-rows: 200px 400px 1fr;

  .link-list-container {
    align-self: start;
    .link-list {
      margin: auto;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
    }
  }

  .icon-link {
    cursor: pointer;
    font-size: 34px;
    margin: 0 5px;

    &:hover {
      color: $green;
    }
  }

  .icon-links-container {
    padding-left: 40px;
    margin-top: 100px;
    margin-bottom: 50px;
    align-self: end;
    align-items: end;
    align-content: end;
  }

  .sidebar-subtitle {
    color: $green;
  }
}
