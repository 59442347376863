@import '../../styles/colours.scss';

$animate: all 0.2s ease-out;

.link {
    text-decoration: none;
    position: relative;
    opacity: 0.8;
    color: grey;
    font-weight: bold;

    &:before,
    &:after {
        content: "";
        position: absolute;
        bottom: -5px;
        width: 0;
        height: 4px;
        margin: 5px 0 0;
        transition: $animate;
        opacity: 0;
        background-color: $green;
        left: 0;
    }

    &:hover {
        cursor: pointer;
        color: black;
        opacity: 1;

        &:before,
        &:after {
            width: 100%;
            opacity: 1;
        }
    }
}

.selected-link {
    text-decoration: none;
    position: relative;
    opacity: 1;
    color: black;
    padding-bottom: 1px;
    font-weight: bold;
    border-bottom: 4px solid $green;
}
