@keyframes fade-in-from-above {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fade-in-from-above {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes fade-in-from-above {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes fade-in-from-above {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-ms-keyframes fade-in-from-above {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-from-below {
  from {
    opacity: 0;
    transform: translateY(2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-from-left {
  from {
    opacity: 0;
    transform: translateX(-4rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-from-right {
  from {
    opacity: 0;
    transform: translateX(4rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-above {
  color: black;
  animation: fade-in-from-above;
  animation-duration: 300ms;
}

.fade-in-below {
  color: black;
  animation: fade-in-from-below;
  animation-duration: 300ms;
}

.fade-in-left {
  color: black;
  animation: fade-in-from-left;
  animation-duration: 300ms;
}

.fade-in-right {
  color: black;
  animation: fade-in-from-right;
  animation-duration: 300ms;
}
