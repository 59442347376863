@import '../../styles/colours';

#top-nav {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  background: white;
  height: 60px;

  #top-nav-links {
    display: flex;
    justify-content: space-between;

    a {
      text-align: center;
      padding-bottom: 4px;
      margin: 25px 5px 0 5px;
    }
  }
}
