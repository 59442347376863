@import './styles/colours';
@import './styles/animations';
@import './styles/grid-mixins';

#main {
  min-height: 100vh;

  .hidden {
    opacity: 0;
  }

  .visible {
    opacity: 1;
  }

  .skills-chips {
    & > * {
      margin: 5px 3px;
    }
  }

  &.desktop, &.desktop-lg {
    display: grid;
    grid-template-columns: 0.2fr 0.8fr;
  }

  &.mobile {
    display: grid;
    grid-template-rows: 50px 1fr;
  }

  .content-container {
    @include main-grid-layout;
    grid-template-areas: '. content .';
    background: white;

    // Apply some top padding on larger viewports (desktop view)
    @media not screen and (max-width: 1024px) {
      padding-top: 100px;
    }

    .content {
      .body {
          font-family: Helvetica, sans-serif;
      }

      grid-area: content;
    }
  }
}

.divider-thick {
  height: 5px;
  border-style: none;
}

.bg-green {
  background: $green;
}
